import React, { Component } from 'react';
import './VoteView.css';
import 'react-autocomplete-input/dist/bundle.css';
import { withStyles } from "@material-ui/core/styles";
import VotePreviousbutton from "../components/VotePreviousbutton";


const styles = theme => ({

    textField: {
        width: "11rem",
        height: "2.5rem",
        background: "#ffffff",
        marginTop: "unset",
        marginLeft: "0.7rem",
    },
    dateTestfield: {
        border: "1px solid #ffffff",
        width: "22rem",
        height: "2.5rem",
        textalign: "center",
        backgroundColor: "#ffffff",
        paddingLeft: "1rem",
        '&:hover': {
            borderBottom: "unset",
        },
    }
});

class VoteView extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    render() {
        const { classes } = this.props;

        return (
            <div className="App">
            <div className="VoteMainContainer">
            <div className="prev-btn-container">
                <VotePreviousbutton handlePrevious={this.props.showPreviousBtn} />
            </div>
                <div className="VoteTopcontainer">
                    <h3 className="vote-data-heading">My Observations</h3>
                    <div className="vote-data-cont">
                        {this.props.votes.map((vote, index) => {
                            let date = new Date(vote.date)
                            return (
                                <div className="vote-data-box-cont" key={index}>
                                    <div className="Votebox">{date.toLocaleDateString()}</div>
                                    <div className="vote-vertical-line" />
                                    <div className="Votebox">{vote.bloom_stage}</div>
                                    <div className="vote-vertical-line" />
                                    <div className="Votebox">{vote.bloom_sentiment}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="VoteBottomContainer">
                  <button className="Back-to-Submit" type="button" onClick={() => this.props.showVote()}>Back&#8287;to&#8287;Submit</button>
                  <div className="contact-container">
                      <h4> Contact</h4>
                      <h3 className="Footerheading centered-on-mobile"><a href="mailto:hello@bountiful.ag">hello@bountiful.ag</a></h3>
                      <h3 className="Footerheading">(805) 704-7157</h3>
                  </div>
                </div>
            </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(VoteView);