import React, { Component } from 'react';
import './App.css';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Vote from './Vote.js';
import Iframe from 'react-iframe';
import 'react-autocomplete-input/dist/bundle.css';
import InfoIcon from "./infoicon";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from 'moment-timezone';

const styles = theme => ({
  root: {
    padding: "none"
  },
  textField: {
    width: "11rem",
    height: "2.5rem",
    background: "#ffffff",
    marginTop: "unset"
  },
  dateTestfield: {
    border: "1px solid #ffffff",
    width: "10rem",
    height: "2.5rem",
    backgroundColor: "#ffffff",
    paddingLeft: "1rem",
  }
});



const sentiment = [
  {value: 'Excellent', label: 'Excellent'},
  {value: 'Good', label: 'Good'},
  {value: 'Average', label: 'Average'},
  {value: 'Poor', label: 'Poor'},
  ];


const zipcodess = [
{value: "Aldrich", label: 'Aldrich'},
{value: "Butte", label: 'Butte'},
{value: "Butte/Padre", label:'Butte/Padre'},
{value: "Carmel", label:'Carmel'},
{value: "Fritz", label:'Fritz'},
{value: "Independence", label:'Independence'},
{value: "Monterey", label:'Monterey'},
{value: "Nonpareil", label:'Nonpareil'},
{value: "Padre", label:'Padre'},
{value: "Shasta", label:'Shasta'},
{value: "Sonora", label:'Sonora'},
{value: "Wood Colony", label:'Wood Colony'},
{value: "California", label:'California'}
];

const almondVariety = [
{value: "Unknown", label:'Unknown'},
{value: "Aldrich", label:'Aldrich'},
{value: "Butte", label:'Butte'},
{value: "Butte/Padre", label:'Butte/Padre'},
{value: "Carmel", label:'Carmel'},
{value: "Fritz", label:'Fritz'},
{value: "Independence", label:'Independence'},
{value: "Monterey", label:'Monterey'},
{value: "Nonpareil", label:'Nonpareil'},
{value: "Padre", label:'Padre'},
{value: "Shasta", label:'Shasta'},
{value: "Sonora", label:'Sonora'},
{value: "Wood Colony", label:'Wood Colony'},
{value: "California", label:'California'}
];


const bloomStage = [
  {value: 'Green Tip', label: 'Green Tip'},
  {value: 'Pink Bud', label: 'Pink Bud'},
  {value: 'Popcorn', label: 'Popcorn'},
  {value: 'Early Bloom', label: 'Early Bloom'},
  {value: 'Full Bloom', label: 'Full Bloom'},
  {value: 'Petal Fall', label: 'Petal Fall'}
  ];

const selectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    background: "#ffffff",
  }),
  control: () => ({
    background: "#ffffff",
    display: "flex",
    width: "11rem",
    height: "2.5rem",
    borderRadius: "unset",
    border: "1px solid #ffffff",
    cursor: "pointer",
    fontFamily: "Sofia Pro",
    fontSize: "16px",
    color: "#000000"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#000000",
    // paddingLeft: "1.2rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // paddingLeft: "3rem"
  })
};

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option,
});

const formFields = {
  email : { required: false },
  date : { required: true },
  location: { required: true },
  almond_variety: { required: true },
  bloom_stage: { required: true },
  bloom_sent: { required: true },
  bee_flight: { required: true }
};

const getToday = function () {
   var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}

const defaultDate = moment().tz("America/Los_Angeles").format("MM/DD/YYYY")
const defaultFormatDate = moment().tz("America/Los_Angeles").format("YYYY-MM-DD")

class App extends Component {
  constructor(props) {
    super(props);

    // init state for form fields
    let initStateObj = {};
    for (var key in formFields) {
      initStateObj[key]= null;
    }

    // add custom state for error
    initStateObj['date'] = defaultFormatDate;
    initStateObj['inputValue'] = defaultDate;
    initStateObj['error'] = null;
    initStateObj['vote_submitted'] = false;
    initStateObj['show_error'] = false;
    initStateObj['zipcodes'] = [];
    initStateObj['almond_variety'] = null;
    initStateObj['bloom_stage'] = null;
    initStateObj['bloom_sent'] = null;
    initStateObj['verification'] = 'failed';
    initStateObj['votes'] = null;
    initStateObj['iframe_url'] = '/map.html?showdata=1&regionId=2';
    initStateObj['existing_email'] = '';
    initStateObj['form_message'] = '';



    // set and init state
    this.state = initStateObj;

    this.handleChange = this.handleChange.bind(this);
    this.handleSpecialChange = this.handleSpecialChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let verification = params.get("verify");

    if (verification) {
      fetch("/api/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ verification: verification }),
      }).then((response) => {
        if (!response.ok) {
          this.setState({ verification: "fail" });
        } else {
          response.json().then((data) => {
            let email = '';
            let regionId = '';
            if(data.votes.length > 0){
              email = data.votes[0].email;
              regionId = data.votes[0].region_id;
            }

            this.setState({
              verification: "verified",
              votes: data.votes,
              iframe_url: '/map.html?showdata=1&regionId='+regionId,
              email: email,
              existing_email: email
            });
          });
        }
      });
    }

    // ----------------------------
    fetch('/api/get_zipcodes', {
        method: 'GET'
    })
    .then(response =>{
      if(!response.ok){

      }else {
        response.json().then(data => {
          this.setState({zipcodes: data.zipcodes})
        });
      }
    });
  }

  // hanlde selection change
  handleChange(event) {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      form_message: ''
    });
  }

  // handle selection change for special inputs
  handleSpecialChange(name, a, value) {
    this.setState({
      [name]: value,
      form_message: ''
    });
  }
  //handle date change
  handleDateChange = (date, value) => {
    this.setState({
      date: moment(date).format("YYYY-MM-DD"),
      inputValue: value
    })
  }
  dateFormatter = str => {
    return str;
  };
  handleSelectedValue = (e, name) => {
    this.setState({
      [name]: e.value,
      form_message: ''
    })
  }

  // submit vote
  handleSubmit(event) {
    event.preventDefault();


    let {
      email,
      date,
      location,
      almond_variety,
      bee_flight,
      bloom_sent,
      bloom_stage,
      verification,
      existing_email
    } = this.state;

    // validate state
    for( var key in formFields){
      if(this.state[key] == null && formFields[key].required){
        this.setState({
          error:"Please enter all required fields.",
          show_error:true,
        });
        return;
      }
    }


    // if user has been verfied, double check email has not change
    if(existing_email && email && existing_email !== email){
      verification = 'failed';
      this.setState({
        verification: "failed"
      });
    }

    fetch('/api/save_bloom', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          date,
          location,
          almond_variety,
          bee_flight,
          bloom_sent,
          bloom_stage,
          verification})
    })
    .then(response =>{
      if(!response.ok){
        this.setState({
          show_error:true,
          error: "An error occured submitting your vote.",
        })
      }else {

        response.json().then((data) => {

          if(this.state.iframe_url === '/map.html?showdata=1'){
            //document.getElementById('myMapId').contentWindow.location.reload();
          }

          this.setState({
            vote_submitted:true,
            show_error:false,
            iframe_url: '/map.html?showdata=1&regionId='+data.regionId[0].id+'&'+Date.now(),
            date:  date,
            almond_variety: null,
            bloom_stage: null,
            votes: data.votes,
            location: null,
            bloom_sent: null,
            bee_flight: null,
            form_message: (data.confirmed ? 'Success!' : 'Success! Check your email to save your vote.')
          })
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <Container width="100%" maxWidth={false} height="1000px">
          <Box display="flex" p={0} bgcolor="background.paper" height="100%">
            <Box p={0} width="30%" bgcolor="#F0F0F0">
              <div className="header-container">
                <a target= "_blank" href = "https://app.bountiful.ag"><img className="header-logo" src="/images/bountiful-logo-purple.png" alt="bountiful_logo" /></a>
                <a target= "_blank" href = "https://app.bountiful.ag"><img className="header-text" src="/images/bountiful-heading-3x.png"/></a>
              </div>
              <div className="instructions">
              Fill out the fields below and click 'Submit' to view bloom, bee flight and chill portion data on the map. Enter your email to save your observations.
              </div>
              <Vote votes={this.state.votes} verification={this.state.verification}/>
              <form onSubmit={this.handleSubmit}>
              { /* EMAIL */}

                <div className="input-field-container">
                  <span class="form-label">Email</span>
                  <input
                    className="input-field"
                    id="email"
                    value={this.state.email}
                    name="email"
                    variant="outlined"
                    label="Optional"
                    placeholder="Enter Your Email"
                    onChange={this.handleChange}
                  />
                  <InfoIcon text="Enter your email to save your observations. Submtting will send a link to your email to view old ones and save new ones." />
                </div>

                { /* DATE */}
                <div className="input-field-container">
                  <span class="form-label">Date</span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.dateTestfield}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      name="date"
                      id="date-picker-dialog"
                      value={this.state.inputValue}
                      inputValue={this.state.inputValue}
                      onChange={this.handleDateChange}
                      maxDate={defaultDate}
                      autoOk={true}
                      rifmFormatter={this.dateFormatter}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <InfoIcon text="This defaults to current day. Select past days to submit observations for historical data." />
                </div>


                 { /* Location */}
                <div className="input-field-container">
                  <span class="form-label">Zipcode</span>
                  <Select
                    className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.location == null) ? 'error' : ''}
                    styles={selectCustomStyles}
                    isSearchable={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e)=> this.handleSelectedValue(e, "location")}
                    options={this.state.zipcodes}
                    value={this.state.zipcodes.filter((option) => option.value === this.state.location)}
                    placeholder="Required" />
                  <InfoIcon text="We collect data by zipcode but display state and region level." />
                </div>

                { /* ALMOND VARIETY */}
                <div className="input-field-container">
                  <span class="form-label">Almond Variety</span>
                  <Select
                    className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.almond_variety == null) ? 'error' : ''}
                    styles={selectCustomStyles}
                    isSearchable={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e)=> this.handleSelectedValue(e, "almond_variety")}
                    options={almondVariety}
                    value={almondVariety.filter((option) => option.value === this.state.almond_variety)}
                    placeholder="Required" />
                  <InfoIcon text="Select the almond variety which is most representative for your observation. If you don't know the variety, select the 'Unknown' option. Data displayed for all varieties." />
                </div>

                { /* BLOOM STAGE */}
                <div className="input-field-container">
                  <span class="form-label">Bloom Stage(<a target="_blank" href="https://7462576.fs1.hubspotusercontent-na1.net/hubfs/7462576/Bountiful%20Bloom%20Tracking%20Final.pdf">&#63;</a>)</span>
                  <Select
                    className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bloom_stage == null) ? 'error' : ''}
                    styles={selectCustomStyles}
                    isSearchable={true}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    onChange={(e)=> this.handleSelectedValue(e, "bloom_stage")}
                    options={bloomStage}
                    value={bloomStage.filter((option) => option.value === this.state.bloom_stage)}
                    placeholder="Required" />
                  <InfoIcon text="Select the bloom stage that is most representative for your observation. Click the icon next to 'Stage' for bloom stage references. Hover over the bloom map to see a realtime breakdown of current bloom stage." />
                </div>

                { /* BLOOM SENT */}
                <div className="input-field-container">
                  <span class="form-label">Bloom Sentiment</span>
                  <Select
                    className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bloom_sent == null) ? 'error' : ''}
                    styles={selectCustomStyles}
                    isSearchable={true}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    onChange={(e)=> this.handleSelectedValue(e, "bloom_sent")}
                    options={sentiment}
                    value={sentiment.filter((option) => option.value === this.state.bloom_sent)}
                    placeholder="Required" />
                  <InfoIcon text="Select the option that is most representative for your observation. Overall sentiment is shown on the bloom map hover over once observations are submitted." />
                </div>

                { /* BEE FLIGHT */}
                <div className="input-field-container">
                <span class="form-label">Bee Activity</span>
                <Select
                    className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bee_flight == null) ? 'error' : ''}
                    styles={selectCustomStyles}
                    isSearchable={true}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    onChange={(e)=> this.handleSelectedValue(e, "bee_flight")}
                    options={sentiment}
                    value={sentiment.filter((option) => option.value === this.state.bee_flight)}
                    placeholder="Required" />
                  <InfoIcon text="Select the option that is most representative for your observation." />
                </div>
                {
                  this.state.vote_submitted ? (<div className="success-msg">{this.state.form_message}</div>) : ""
                }
                {
                  this.state.show_error ? <div className="error-msg">{this.state.error}</div> : ""
                }
               {/*<button className="btn" type="submit" >Submit</button>*/}

              </form>
              <div className="vote-ended">Observations ended on 3/14/2024</div>
              

              <div className="contact">Contact Us <a href={"mailto:hello@bountiful.ag"}>hello@bountiful.ag</a> (805)&#8287;704-7157</div>
              <div id ='sec'><a target="_blank" href="https://app.termly.io/document/privacy-policy/d409c2a1-6aa2-4ff4-838e-78093046d705">Privacy Policy</a><a target="_blank" href="https://app.termly.io/document/terms-of-use-for-saas/c1e8fb35-9953-4459-8e4e-eb539c6b6669">Terms and Conditions</a></div>
            </Box>
            <Box p={0} width="70%" flexShrink={1}>
              <Iframe url={this.state.iframe_url}
                width="100%"
                height="100%"
                id="myMapId"
                className="myClassname"
                display="initial"
                position="relative"
                frameBorder="0"
                />
            </Box>
          </Box>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
