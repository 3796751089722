import React, { useState } from "react";
import "./infoicon.css";

const InfoIcon = ({text}) => {
  const [hover, setHover] = useState(false);

  return (
    <div className="mob-info-container">
      <img
        className="img"
        src="/images/info.svg"
        alt="info"
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      />
      {hover ? (
        <div className="mob-info-text-container">
          {text}
        </div>
      ) : null}
    </div>
  );
};

export default InfoIcon;
