import React from "react";
import previouslogo from "../../assets/3x/Left@3x.png";
import "../views/VoteView.css";

const VotePreviousbutton = (props) => {
  return (
    <img
      className="vote-previous-btn"
      onClick={() => props.handlePrevious()}
      src={previouslogo}
      alt="next-button"
    />
  );
};

export default VotePreviousbutton;
