import React, { useState, useEffect } from "react";

import HomeMobileApp from "./HomeView";
import MapMobileApp from "./MapView";
import VoteMobileApp from './VoteView';

class Mobileviewcontainer extends React.Component { 

  constructor(props) {
    super(props);

      this.state  = {
        step: null,
        isSubmitWithEmail: true,
        showPreviousBtn: false,
        showNextBtn:false,
        votes: null,
        region: null
      };

      this.showVoteList =this.showVoteList.bind(this);
      this.showMap = this.showMap.bind(this);
      this.showVoteForm = this.showVoteForm.bind(this);
      this.goBackToMap = this.goBackToMap.bind(this);
      this.updateRegion = this.updateRegion.bind(this);
  }

  componentDidMount() {
    this.verifyEmail()
    // this.showMap();
  }

  verifyEmail() {
    const searchParam = window.location.search
    const params = new URLSearchParams(searchParam);
    const verification = params.get("verify");
    if (verification) {
      fetch("/api/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ verification: verification }),
      }).then((response) => {
        if (!response.ok) {
          // we can't show votes but map is ok
           this.setState({
            step: 'map',
            showPreviousBtn: this.showVoteForm,
            showNextBtn: false,
          });

        } else {
          response.json().then((data) => {
            let email = '';
            let vote_region = null;
            if(data.votes.length > 0){
              email = data.votes[0].email;
              email = email.replace(/^\s+|\s+$/g, '');
              vote_region = data.votes[0].region_id;
            }

            this.setState({
              step: 'map',
              showPreviousBtn: this.showVoteForm,
              showNextBtn: this.showVoteList,
              votes: data.votes,
              email: email,
              existing_email: email,
              region: vote_region
            });
          });
        }
      });
    } else {
      this.setState({
        step: 'vote',
        showPreviousBtn: false,
        handleNext: this.showMap,
      });
    }
  }

  showVoteForm(){
    this.setState({
      step: 'vote',
      handleNext: this.showMap,
    })
  }

  showVoteList(){
    this.setState({
      step: 'list',
    })
  }

  showMap(votes){
    this.setState({
      step: 'map',
      showPreviousBtn :this.showVoteForm,
      votes:votes
    })
  }

  goBackToMap(){
    this.setState({
      step: 'map',
      showPreviousBtn :this.showVoteForm
    })
  }

  updateRegion = id => {
    this.setState({
      region: id
    })
  }
  
  render() {

    return (
        <div>
        {this.state.step === 'vote'? (
          <HomeMobileApp handleNext={this.state.handleNext} email={this.state.email} updateRegion={this.updateRegion}/>
        ) : this.state.step === 'map' ? (
          <MapMobileApp showPreviousBtn={this.state.showPreviousBtn} showNextBtn={this.state.showNextBtn} region={this.state.region}/>
        ) : this.state.step === 'list' ? (
          <VoteMobileApp votes={this.state.votes} showPreviousBtn={this.goBackToMap} showVote={this.showVoteForm}/>
        ): null}
        </div>
    );
  }
};

export default Mobileviewcontainer;
