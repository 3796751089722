import React, { Component } from "react";
import "./Vote.css";

const getToday = function (date) {
  var d = new Date(date),
       month = '' + (d.getMonth() + 1),
       day = '' + d.getDate(),
       year = d.getFullYear();

   if (month.length < 2) 
       month = '0' + month;
   if (day.length < 2) 
       day = '0' + day;

   return [month, day, year].join('/');
}
class Vote extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    if(this.props.verification == 'failed' || !this.props.votes)
    	return('');
    return (
      <div className="previous-data-cont">
        <h3 className="previous-data-heading">Previous Observations</h3>
        {this.props.votes.map((vote, index) => {
			   let date = getToday(vote.date)
          return (
            <div className="previos-data-box-cont" key={index}>
              <div className="box">{date}</div>
              <div className="vertical-line" />
              <div className="box">{vote.bloom_stage}</div>
              <div className="vertical-line" />
              <div className="box">{vote.bloom_sentiment}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Vote;
