import React from "react";
import nextlogo from "../../assets/3x/Right@3x.png";

const Nextbutton = (props) => {
  return (
    <img
      className="next-btn"
      onClick={() => props.handleNext()}
      src={nextlogo}
      alt="next-button"
    />
  );
};

export default Nextbutton;
