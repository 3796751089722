import React, { Component } from 'react';
import './MapView.css';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Iframe from 'react-iframe';
import 'react-autocomplete-input/dist/bundle.css';
import InfoIcon from "./infoicon";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Nextbutton from "../components/Nextbutton";
import Previousbutton from "../components/Previousbutton";

const styles = theme => ({
    root: {
        padding: "none"
    },
    textField: {
        width: "11rem",
        height: "2.5rem",
        background: "#ffffff",
        marginTop: "unset",
        marginLeft: "0.7rem",
    },
    dateTestfield: {
        border: "1px solid #ffffff",
        width: "22rem",
        height: "2.5rem",
        textalign: "center",
        backgroundColor: "#ffffff",
        paddingLeft: "1rem",
        '&:hover': {
            borderBottom: "unset",
        },
    }
});

class MapView extends Component {
    constructor(props) {
        super(props);

        // init state for form fields
        let initStateObj = {};
        initStateObj['iframe_url'] = `/mobmap-slider.html?showdata=1&regionId=1`;
        this.state = initStateObj;
    }

    componentDidMount() {
        let height  = window.innerHeight;
        this.setState({winHeight:height});
    }

    render() {
        const { classes } = this.props;
        // console.log("biswa-- ", this.props.region);
        return (
            <div className="App">
                    {<div className="prev-btn-container">
                        {
                            this.props.showPreviousBtn ? 
                            (
                                <Previousbutton handlePrevious={this.props.showPreviousBtn} />
                            ) : null
                        }
                    </div>}
                    {<div className="next-btn-container">
                        {
                            this.props.showNextBtn ?
                            (
                                <Nextbutton handleNext={this.props.showNextBtn} />
                            ) : null
                        }
                    </div>}
                <div className="MapMaincontainer">
                    <Iframe url={this.state.iframe_url}
                        width="100%"
                        height={this.state.winHeight}
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                        frameBorder="0"
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(MapView);