import React, { Component } from 'react';
import './HomeView.css';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import 'react-autocomplete-input/dist/bundle.css';
import InfoIcon from "./infoicon";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Nextbutton from "../components/Nextbutton";
import moment from 'moment-timezone';

const styles = theme => ({
  root: {
    padding: "none"
  },
  textField: {
    width: "11rem",
    height: "2.5rem",
    background: "#ffffff",
    marginTop: "unset",
    marginLeft: "0.7rem",
  },
  dateTestfield: {
    border: "1px solid #ffffff",
    width: "22rem",
    height: "2.5rem",
    textalign: "center",
    backgroundColor: "#ffffff",
    paddingLeft: "1rem",
    '&:hover': {
      borderBottom: "unset",
    },
  }
});



const sentiment = [
  { value: 'Excellent', label: 'Excellent' },
  { value: 'Good', label: 'Good' },
  { value: 'Average', label: 'Average' },
  { value: 'Poor', label: 'Poor' },
];


const zipcodess = [
  { value: "Aldrich", label: 'Aldrich'},
  { value: "Butte", label: 'Butte'},
  { value: "Butte/Padre", label: 'Butte/Padre' },
  { value: "Carmel", label: 'Carmel' },
  { value: "Fritz", label: 'Fritz' },
  { value: "Independence", label: 'Independence' },
  { value: "Monterey", label: 'Monterey' },
  { value: "Nonpareil", label: 'Nonpareil' },
  { value: "Padre", label: 'Padre' },
  { value: "Shasta", label: 'Shasta' },
  { value: "Sonora", label: 'Sonora' },
  { value: "Wood Colony", label: 'Wood Colony' },
  { value: "California", label: 'California' }
];

const almondVariety = [
  { value: "Unknown", label: 'Unknown'},
  { value: "Aldrich", label: 'Aldrich' },
  { value: "Butte", label: 'Butte' },
  { value: "Butte/Padre", label: 'Butte/Padre' },
  { value: "Carmel", label: 'Carmel' },
  { value: "Fritz", label: 'Fritz' },
  { value: "Independence", label: 'Independence' },
  { value: "Monterey", label: 'Monterey' },
  { value: "Nonpareil", label: 'Nonpareil' },
  { value: "Padre", label: 'Padre' },
  { value: "Shasta", label: 'Shasta' },
  { value: "Sonora", label: 'Sonora' },
  { value: "Wood Colony", label: 'Wood Colony' },
  { value: "California", label: 'California' }
];


const bloomStage = [
  { value: 'Green tip', label: 'Green Tip' },
  { value: 'Pink bud', label: 'Pink Bud' },
  { value: 'Popcorn', label: 'Popcorn' },
  { value: 'Early Bloom', label: 'Early Bloom' },
  { value: 'Full Bloom', label: 'Full Bloom' },
  { value: 'Petal fall', label: 'Petal Fall' }
];

const selectCustomStyles = {
  menu: (provided, state) => ({
    ...provided,
    background: "#ffffff",
  }),
  control: () => ({
    background: "#ffffff",
    display: "flex",
    width: "19rem",
    height: "2.5rem",
    borderRadius: "unset",
    border: "1px solid #ffffff",
    cursor: "pointer",
    fontFamily: "Sofia Pro",
    fontSize: "16px",
    textAlign: "center",
    color: "#000000"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#000000",
    paddingLeft: "5rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    paddingLeft: "5rem"
  })
};

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option,
});

const formFields = {
  email: { required: false },
  date: { required: true },
  location: { required: true },
  almond_variety: { required: true },
  bloom_stage: { required: true },
  bloom_sent: { required: true },
  bee_flight: { required: true }
};

const getToday = function () {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}

const defaultDate = moment().tz("America/Los_Angeles").format("MM/DD/YYYY")
const defaultFormatDate = moment().tz("America/Los_Angeles").format("YYYY-MM-DD")

class HomeView extends Component {
  constructor(props) {
    super(props);

    // init state for form fields
    let initStateObj = {};
    for (var key in formFields) {
      initStateObj[key] = null;
    }

    // add custom state for error
    initStateObj['date'] = defaultFormatDate;
    initStateObj['inputValue'] = defaultDate;
    initStateObj['error'] = null;
    initStateObj['vote_submitted'] = false;
    initStateObj['show_error'] = false;
    initStateObj['zipcodes'] = [];
    initStateObj['almond_variety'] = null;
    initStateObj['bloom_stage'] = null;
    initStateObj['bloom_sent'] = null;
    initStateObj['verification'] = this.props.email ? 'verified' : 'failed';
    initStateObj['votes'] = null;
    initStateObj['iframe_url'] = '/map.html?showdata=0';
    initStateObj['existing_email'] = this.props.email;
    initStateObj['email'] = this.props.email;



    // set and init state
    this.state = initStateObj;

    this.handleChange = this.handleChange.bind(this);
    this.handleSpecialChange = this.handleSpecialChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    fetch('/api/get_zipcodes', {
      method: 'GET'
    })
      .then(response => {
        if (!response.ok) {

        } else {
          response.json().then(data => {
            this.setState({ zipcodes: data.zipcodes })
          });
        }
      });
  }

  // hanlde selection change
  handleChange(event) {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value
    });
  }

  // handle selection change for special inputs
  handleSpecialChange(name, a, value) {
    console.log("biswa", name, value)
    this.setState({
      [name]: value
    });
  }
  //handle date change
  handleDateChange = (date, value) => {
    this.setState({
      date: moment(date).format("YYYY-MM-DD"),
      inputValue: value
    })
  }
  handleSelectedValue = (e, name) => {
    this.setState({
      [name]: e.value
    })
  }

  // submit vote
  handleSubmit(event) {
    event.preventDefault();
    let {
      email,
      date,
      location,
      almond_variety,
      bee_flight,
      bloom_sent,
      bloom_stage,
      verification,
      existing_email
    } = this.state;

    // validate state
    for (var key in formFields) {
      if (this.state[key] == null && formFields[key].required) {
        this.setState({
          error: "Please enter all required fields.",
          show_error: true,
        });
        return;
      }
    }

    // if user has been verfied, double check email has not change
    if (existing_email && email && existing_email !== email) {
      verification = 'failed';
      this.setState({
        verification: "failed"
      });
    }

    fetch('/api/save_bloom', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        date,
        location,
        almond_variety,
        bee_flight,
        bloom_sent,
        bloom_stage,
        verification
      })
    })
      .then(response => {
        if (!response.ok) {
          this.setState({
            show_error: true,
            error: "An error occured submitting your vote.",
          })
        } else {
          response.json().then(data => {
            let votes = null;
            if (data.votes)
              votes = data.votes;

            if (data.regionId.length) {
              this.props.updateRegion(data.regionId[0].id);
            } else {
              this.props.updateRegion(0);
            }

            if (!email) {
              this.props.handleNext();
            }
            this.setState({
              vote_submitted: true,
              show_error: false,
              date: getToday(),
              almond_variety: null,
              bloom_stage: null,
              votes: null,
              location: null,
              bloom_sent: null,
              bee_flight: null
            });
            this.props.handleNext(votes);
          })
        }
      });
  }

  render() {
    const { classes } = this.props;

    return (
      // <div className="App">
        <div className="Maincontainer">
          <div className="mob-header-container">
            <a target="_blank" href="https:app.bountiful.ag"><img className="mob-header-logo" src="/images/bountiful-logo-purple.png" alt="bountiful_logo" /></a>
            <a target="_blank" href="https:app.bountiful.ag"><img className="mob-header-text" src="/images/bountiful-heading-3x.png" /></a>
            <InfoIcon text="Fill out the fields below and click 'Submit' to view bloom, bee flight and chill portion data on the map. Enter your email to save your observations." />

          </div>
          <form onSubmit={this.handleSubmit}>

            <div className="mob-input-field-container">
              <input
                className="mob-input-field"
                id="email"
                value={this.state.email}
                name="email"
                variant="outlined"
                label="Optional"
                placeholder="Enter Your Email"
                onChange={this.handleChange}
              />
            </div>

            { /* DATE */}
            <div className="mob-input-field-container">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.dateTestfield}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  name="date"
                  id="date-picker-dialog"
                  inputValue={this.state.inputValue}
                  value={this.state.date}
                  onChange={this.handleDateChange}
                  maxDate={defaultDate}
                  autoOk={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>


            { /* Location */}
            <div className="mob-input-field-container">
              <Select
                className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.location == null) ? 'error' : ''}
                styles={selectCustomStyles}
                isSearchable={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => this.handleSelectedValue(e, "location")}
                options={this.state.zipcodes}
                value={this.state.zipcodes.filter((option) => option.value === this.state.location)}
                placeholder="Zipcode" />
            </div>

            { /* ALMOND VARIETY */}
            <div className="mob-input-field-container">
              <Select
                className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.almond_variety == null) ? 'error' : ''}
                styles={selectCustomStyles}
                isSearchable={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => this.handleSelectedValue(e, "almond_variety")}
                options={almondVariety}
                value={almondVariety.filter((option) => option.value === this.state.almond_variety)}
                placeholder="Almond Variety" />
            </div>

            { /* BLOOM STAGE */}
            <div className="mob-input-field-container">
              <Select
                className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bloom_stage == null) ? 'error' : ''}
                styles={selectCustomStyles}
                isSearchable={true}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={(e) => this.handleSelectedValue(e, "bloom_stage")}
                options={bloomStage}
                value={bloomStage.filter((option) => option.value === this.state.bloom_stage)}
                placeholder="Bloom Stage" />
            </div>

            { /* BLOOM SENT */}
            <div className="mob-input-field-container">
              <Select
                className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bloom_sent == null) ? 'error' : ''}
                styles={selectCustomStyles}
                isSearchable={true}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={(e) => this.handleSelectedValue(e, "bloom_sent")}
                options={sentiment}
                value={sentiment.filter((option) => option.value === this.state.bloom_sent)}
                placeholder="Bloom Sentiment" />
            </div>

            <div className="mob-input-field-container">
              <Select
                className={(this.state.error === "Please enter all required fields." && this.state.show_error && this.state.bee_flight == null) ? 'error' : ''}
                styles={selectCustomStyles}
                isSearchable={true}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={(e) => this.handleSelectedValue(e, "bee_flight")}
                options={sentiment}
                value={sentiment.filter((option) => option.value === this.state.bee_flight)}
                placeholder="Bee Activity" />
            </div>
            {
              this.state.vote_submitted && this.state.email && this.state.verification == 'failed' ? (<div className="success-msg">Success! Check your email to save your vote.</div>) : ""
            }
            {
              this.state.vote_submitted && this.state.email == null ? (<div className="success-msg">Success!</div>) : ""
            }
            {
              this.state.vote_submitted && this.state.email && this.state.verification == 'verified' ? (<div className="success-msg">Success!</div>) : ""
            }
            {
              this.state.show_error ? <div className="error-msg">{this.state.error}</div> : ""
            }
            <button className="mob-btn" type="submit" >Submit</button>
          </form>
        </div>
      // </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomeView);
